<template>
  <div id="login" >
    <recruit-section></recruit-section>
  </div>
</template>

<script>
// @ is an alias to /src
import http from "../components/services/http-common";
import ApiService from "../components/services/ApiServices";
import EventBus  from "../components/services/EventBus";
import router from "../router/index";
import moment from 'moment';
import RecruitSection from "../components/RecruitSection"
import Swal from 'sweetalert2';

const dateRegex = new RegExp('[0-9]{1,2}(/|-)[0-9]{1,2}(/|-)[0-9]{4}');

var minBirthDay = moment().startOf("year").subtract(19, "years").format("YYYY-MM-DD");

export default {
  name: 'Login',
  watch:{
    // "form.attachmentFile":{
    //   handler(val) {
    //     console.log(val.size)
    //   }
    // }
  },
  components: {
    "recruit-section": RecruitSection
  },
  props: {
      data: {
        type: String,
        default: "ssss"

      }
  },
  data() {
    return {
      show:false,
      username: "",
      password: "",
      passwordShow: false,
      logging: false,
      loginValid: true,
      test: "",
      loginDialog: false,
      rememberLogin: false,

      CANDIDATE_URL: "candidates",
      jobs:[],
      cities:[],
      districts:[],
      wards:[],
      district2s:[],
      ward2s:[],

      ethnicities:[],
      origins:[],
      religions:[],

      educationLevels:[],
      educationMajors:[],
      universities:[],
      yearExperiences:[
        {text: " Chưa có kinh nghiệm", value: 0},
        {text: " 6 tháng", value: 0.5},
        {text: " 1 năm", value: 1},
        {text: " 2 năm", value: 2},
        {text: " 3 năm", value: 3},
        {text: " Hơn 3 năm", value: 4},
      ],
      valid: true,
      avatarPreview: "/sample.png",
      attachmentFile:undefined,
      avatarFile:undefined,
      isLoading:false,
      recruitChannels:[
        {text: "Mạng xã hội", value: "MXH"},
        {text: "Tờ rơi", value: "TR"},
        {text: "Được giới thiệu", value: "N"},
        {text: "Trang tuyển dụng", value: "TTD"},
      ],
      pharmaCerts:[
        {text: "Không có", value: "KHONG"},
        {text: "CCHN Cao Đẳng", value: "CD"},
        {text: "CCHN Đại Học", value: "DH"},
      ],
      form: {
        jobCode:null,
        name:"",
        gender: null,
        birthday:moment().format("YYYY-MM-DD"),
        phone:"",
        mail:"",
        cityCode:null,
        districtCode:null,
        wardCode:null,
        address:"",

        city1Code:"",
        city2Code:"",

        dCityCode:null,
        dDistrictCode:null,
        dWardCode:null,
        dAddress:"",

        ethnicityCode: "",
        originCode: "",
        religionCode: "",

        educationLevelCode:null,
        educationMajorCode:null,
        universityCode:null,
        yearExperience:0,
        weight:0,
        height:0,
        idNumber:"",
        idDate:moment().format("YYYY-MM-DD"),
        idPlace: "",
        note:"",
        recruitChannel: "TTD",
        graduateTypeCode:null,
        pharmaCert: null,
      },
      defaultForm: {
        jobCode:null,
        name:"",
        gender: null,
        birthday:moment().format("YYYY-MM-DD"),
        phone:"",
        mail:"",
        
        cityCode:null,
        districtCode:null,
        wardCode:null,
        address:"",

        city1Code:"",
        city2Code:"",

        dCityCode:null,
        dDistrictCode:null,
        dWardCode:null,
        dAddress:"",
        
        ethnicityCode: "",
        originCode: "",
        religionCode: "",

        educationLevelCode:null,
        educationMajorCode:null,
        universityCode:null,
        yearExperience:0,
        weight:0,
        height:0,
        idNumber:"",
        idDate:moment().format("YYYY-MM-DD"),
        idPlace: "",
        note:"",
        recruitChannel: "TTD",
        graduateTypeCode:null,
        pharmaCert: null,
      },
      rules: {
        required: v=>!!v || 'Nhập dữ liệu',
        validEmail: v =>  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email không đúng',
        validDate: v=> dateRegex.test(v) || 'Dữ liệu không đúng, định dạng ngày dd/mm/yyyy',
        minDate: v=> !moment(v, "dd/mm/yyyy").isAfter(minBirthDay) || 'Bạn cần đủ tuổi để nộp hồ sơ'
      }
    }
  },
  computed: {
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    },
    avatarError: function(){
      if(this.avatarFile===null)
        return true;
      console.log(this.avatarFile)
      return false;
    },
    height () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    // avatar(){
    //     if(!!this.form.avatar)
    //         return process.env.VUE_APP_BASE_URL + this.form.avatar.replace('wwwroot/', '');
    //     else
    //         return '/sample.png';
    // },
  },
  methods: {
    getApple()
    {
        return http.get("employees/apple-download").then(res => {
            // window.location.replace(res.data.url);
            // let a = document.createElement("a");
            // document.body.appendChild(a);
            // a.style = "display: none";
            // a.href = res.data.url;
            // a.click();
            // document.body.removeChild(a);
            window.location.href =res.data.url 
        })
    },
    uploadAvatar(file) {  
        if (file) {
          this.avatarPreview = URL.createObjectURL(file);
        }
    },
    chooseAvatar(){
      this.$refs.avatarInput.$refs.input.click()
    },
    loadImg() {
    },
    login () {
      var vm = this;
      this.logging = true;
      this.$refs.loginForm.validate();
      if(this.loginValid)
      {
        return http.post('account',{
          UserName: this.username,
          Password: this.password
        })
        .then(response =>  {
          // this.logging = false;
          vm.test = response;
          var strTOKEN = JSON.stringify(response.data.user);
          localStorage.setItem('user', strTOKEN);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userClaim', JSON.parse(strTOKEN).claims );
          localStorage.setItem('validTo', response.data.validTo);
          localStorage.setItem('WebAllowed', response.data.webAllowed);

          if(this.rememberLogin){
            localStorage.setItem('rememberUser', this.username);
            localStorage.setItem('rememberPassword', this.password);
          }else {
            localStorage.removeItem('rememberUser')
            localStorage.removeItem('rememberPassword')
          }

          let redirect = vm.$route.query.redirect
          let route = vm.$route.query.route
          let id = vm.$route.query.id
          let id2 = vm.$route.query.id2

          console.log("DEBUG:",redirect==true)
          if(redirect ==true){
            router.push({ name: route, params:{
              id: id,
              id2: id2,
            }})
          }else {
            router.push({ name: 'Home'})
          }
          
        })
        .catch(function (error) {
          vm.logging = false;
          vm.test = error;
          switch (error.response.status) {
            case 401: {
              let eventObj = {...error.response.data};
              eventObj.color = "error";
              EventBus.$emit('hasError', eventObj);
              break;
            }
          }
        })
        .finally(()=> {
          this.logging=false
          });
      } 
    },

    fetchJob () {
      return http.get("candidates/fetch-job")
      .then(res => {
          this.jobs = res.data.items;
      }).catch(err => {throw err});
    },
    fetchCity () {
      return http.get("cities", {
          params: {dropDown: 1}
      }).then(res => {
          this.cities = res.data.items;
      }).catch(err => {
          console.log(err);
      });
    },
    fetchDistrict (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.districts = res.data;
        });
    },
    fetchWard (val) {
      if(val)
        return http.get('districts/fetch-ward/'+val).then(res => {
            this.wards = res.data;
        });
    },

    fetchDistrict2 (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.district2s = res.data;
        });
    },
    fetchWard2 (val) {
      if(val)
        return http.get('districts/fetch-ward/'+val).then(res => {
            this.ward2s = res.data;
        });
    },
    fetchEducationLevel(){
      ApiService.fetchEducationLevel().then(res=>{
        this.educationLevels = res.data;
      })
    },
    fetchEducationMajor(){
      ApiService.fetchEducationMajor().then(res=>{
        this.educationMajors = res.data;
      })
    },
    fetchUniversity(){
      ApiService.fetchUniversity().then(res=>{
        this.universities = res.data;
      })
    },
    fetchEthnicity(){
      return ApiService.fetchEthnicity().then(r => {
        this.ethnicities =  r.data
      })
    },
    fetchOrigin(){
      return ApiService.fetchOrigin().then(r => {
        this.origins =  r.data
      })
    },
    fetchReligion(){
      return ApiService.fetchReligion().then(r => {
        this.religions =  r.data
      })
    },
    
    scrollMeTo(refName) {
      // var element = this.$refs[refName];
      // var top = element.offsetTop;

      window.scrollTo(0, 1200);
    },
    applyJob() {
      var formData = new FormData();
      if(this.attachmentFile != null){
        this.form.attachmentFile = this.attachmentFile.forEach(file => {
          formData.append("attachmentFile", file);
        });
      }
      if(this.form.birthday ==null)
      {
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Hãy nhập ngày sinh của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }
      if(this.avatarFile)
        formData.append("avatar", this.avatarFile);
      else
      {
        this.avatarFile = null;
        this.valid = false;
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Chọn ảnh avatar của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }

      this.$refs.candidate.validate();
      if(this.valid){
        this.isLoading=true;
        return http.post(this.CANDIDATE_URL, formData).then(res=>{
          this.$refs.candidate.reset();
          this.form = Object.assign({}, this.defaultForm);
          this.isLoading=false;
          this.form.height = 0;
          this.form.weight = 0;

          Swal.fire(
            {
              title: 'Thông báo',
              html: '<p>Cảm ơn bạn đ&atilde; ứng tuyển v&agrave;o Hệ Thống Nh&agrave; Thuốc Trung Sơn.</p> <p>Theo nhu cầu tuyển dụng nhận sự của c&ocirc;ng ty. Ch&uacute;ng t&ocirc;i sẽ chủ động li&ecirc;n hệ với c&aacute;c hồ sơ ph&ugrave; hợp để th&ocirc;ng b&aacute;o lịch phỏng vấn.</p> <p>Th&acirc;n mến!!</p><p>&nbsp;</p><p><em>Th&ocirc;ng tin li&ecirc;n hệ SDT: <a class="text-is-phone-number">0946 345 242</a><a class="text-is-phone-number">&nbsp;</a><span class="text">/ </span><a class="text-is-phone-number">0888 786 787</a></em></p>',
              icon:'success',
              closeButtonText: 'Đồng ý',
              showCloseButton: true
            }).then(result => {
              window.location.reload();
            })
        }).catch(err=>{console.log(err)})
        .finally(()=>{
          this.isLoading=false;
        })
      } else {
          let eventObj = {
              color: "error",
              message: "Kiểm tra lại dữ liệu",
              toast: false,
          };
          EventBus.$emit('hasError', eventObj);
      }
    },
  },
  
  updated()
  {
    
  },
  
  mounted() {
    this.fetchJob();
    this.fetchCity();
    this.fetchEducationLevel();
    this.fetchEducationMajor();
    this.fetchUniversity();
    this.fetchEthnicity();
    this.fetchOrigin();
    this.fetchReligion();

    //load remember
    let rememberUser = localStorage.getItem('rememberUser')
    let rememberPassword = localStorage.getItem('rememberPassword')
    if(rememberUser && rememberPassword){
      this.username = rememberUser
      this.password = rememberPassword
      this.rememberLogin = true
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .slide-fade-enter-active {
  transition: all .9s ease-in-out;
} */
/* .slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
} */
/* .slide-fade-leave-active below version 2.1.8 */
/* .slide-fade-enter, .slide-fade-leave-to
 {
  transform: translateX(-10px);
  opacity: 0;
} */
::v-deep .swal2-content {
  text-align: left !important;
}
.container.custom{
  max-width: 800px;
}

/* .v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
} */

.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .7;
    position: absolute;
    width: 100%;
}
</style>